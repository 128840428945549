// copied from https://stackoverflow.com/a/4770179
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener(
    "test",
    null,
    Object.defineProperty({}, "passive", {
      // eslint-disable-next-line getter-return
      get: function () {
        supportsPassive = true;
      },
    })
  );
} catch (e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent =
  "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

// call this to Disable
export const disableScroll = (scrollReplacement) => {
  window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
  window.addEventListener("keydown", preventDefaultForScrollKeys, false);
  window.addEventListener("mousemove", preventDefault, false);

  window.addEventListener("DOMMouseScroll", scrollReplacement, false); // older FF
  window.addEventListener(wheelEvent, scrollReplacement, wheelOpt); // modern desktop
  window.addEventListener("touchmove", scrollReplacement, wheelOpt); // mobile
  window.addEventListener("keydown", scrollReplacement, false);
  window.addEventListener("mousemove", scrollReplacement, false);
};

// call this to Enable
export const enableScroll = (scrollReplacement) => {
  window.removeEventListener("DOMMouseScroll", preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener("touchmove", preventDefault, wheelOpt);
  window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
  window.removeEventListener("mousemove", preventDefault, false);

  window.removeEventListener("DOMMouseScroll", scrollReplacement, false); // older FF
  window.removeEventListener(wheelEvent, scrollReplacement, wheelOpt); // modern desktop
  window.removeEventListener("touchmove", scrollReplacement, wheelOpt); // mobile
  window.removeEventListener("keydown", scrollReplacement, false);
  window.removeEventListener("mousemove", scrollReplacement, false);
};
