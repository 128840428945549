import React from "react";
import styled from "styled-components/macro";
import { map } from "lodash";
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

export default ({ crumbs = [{ path: "", text: "" }], currentPath = "" }) => {
  return (
    <BreadcrumbsWrapper>
      <Breadcrumbs aria-label="breadcrumb">
        {map(crumbs, (crumb, index) => {
          let { path, text } = crumb;
          return (
            <Link to={path} key={`crumb--${index}`}>
              {text}
            </Link>
          );
        })}
        <Typography color="textPrimary">{currentPath}</Typography>
      </Breadcrumbs>
    </BreadcrumbsWrapper>
  );
};

const BreadcrumbsWrapper = styled.div``;
