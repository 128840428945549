import { toInteger } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
//for alert dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveIcon from '@material-ui/icons/Save';

import {
  addInventory,
  getFeedsByPodcast,
  getInventoryByPodcast,
  getPodcast,
  patchInventory,
  patchPodcast,
} from '../api/api';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: theme.spacing(-2),
  },
}));

export default ({ setOpenSnackbar, podcastId }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState([]);

  const classes = useStyles();
  const [inventory_categories, setInventoryCategories] = useState([]);
  const [feeds, setFeeds] = useState([]);
  const [podcast, setPodcast] = useState({});

  const macros = [
    { name: '[EpisodenID]', replacement: 'The number of the episode (if set)' },
    {
      name: '[downloadSessionID]',
      replacement:
        'Unique session ID for client and mediafile (called content_client_id in ASAP)',
    },
    { name: '[random]', replacement: 'A randomized 7 digit number' },
    {
      name: '[keywords]',
      replacement: 'The keywords of the episode as set in the feed',
    },
    {
      name: '[art19SeriesId]',
      replacement: 'The art19 series ID as set on the podcast',
    },
  ];
  useEffect(() => {
    const doGetInventory = async () => {
      const response = await getInventoryByPodcast(podcastId);
      const data = await response.json();
      const { results } = data;
      setInventoryCategories(results);
    };

    doGetInventory();

    const doGetFeeds = async () => {
      const response = await getFeedsByPodcast(podcastId);
      const data = await response.json();
      const { results } = data;
      setFeeds(results);
    };

    doGetFeeds();

    const doGetPodcast = async () => {
      const response = await getPodcast(podcastId);
      const data = await response.json();
      setPodcast(data);
    };

    doGetPodcast();

  }, [podcastId]);

  const handleAdcallChange = (event, tagname, index) => {
    const adcallsCopy = inventory_categories.slice();
    adcallsCopy[index][tagname] = event.target.value;
    setInventoryCategories(adcallsCopy);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrors([]);
  };

  let savePodcast = async () => {
    let updatedCategories = [];
    for (const adcall of inventory_categories) {
      //check if new inventory has name (minimum requirement)
      if (!adcall.name) {
        setOpenDialog(true);
        return;
      }
    }
    for (const adcall of inventory_categories) {
      if (adcall.id) {
        const response = await patchInventory(adcall.id, adcall);
        const data = await response.json();
        updatedCategories.push(data);
      } else {
        const response = await addInventory(adcall);
        const data = await response.json();
        updatedCategories.push(data);
      }
      setInventoryCategories(updatedCategories);
      setOpenSnackbar(true);
    }
  };

  let addInventoryCat = async () => {
    const data = {
      id: 0,
      name: '',
      url: '',
      podcast: toInteger(podcastId),
    };
    const adcallsCopy = inventory_categories.slice();
    adcallsCopy.push(data);
    setInventoryCategories(adcallsCopy);
  };

  let updatePodcast = async () => {
    const podcastCopy = Object.assign({}, podcast);
    podcastCopy.generate_variations_without_ads =
      !podcastCopy.generate_variations_without_ads;

    const response = await patchPodcast(podcast.id, podcastCopy);
    const data = await response.json();
    setPodcast(data);
  };

  let checkInventory = () => {
    if (podcast.network !== 48) {
      return true;
    }

    let adcalls = [];

    if (podcast.network_adcalls) {
      adcalls = podcast.network_adcalls;
    }

    inventory_categories.map((item) => {
      if (item.url === '') {
        let t = adcalls.filter((a) => a.name === item.name).pop();
        if (t) {
          item.isNetwork = (podcast.network_adcalls);
          item.url = t.url;
          item.locked = false;
        }
      }
    });
    return true;
  };

  return (
    <PodcastSettings>
      <InventoryTags>
        <h2>Macros and their replacements</h2>
        <Macros>
          <Card className={'card'} key={`card--macros`}>
            <CardContent>
              {macros.map((macro) => {
                return (
                  <Macro key={macro.name}>
                    <Headline>
                      <h3>{macro.name}</h3>
                    </Headline>

                    <Text style={{ width: '80%', color: 'black' }}>
                      {macro.replacement}
                    </Text>
                  </Macro>
                );
              })}
            </CardContent>
          </Card>
        </Macros>
        <h2>Manage Inventory URLs</h2>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Inventory name missing'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please provide a name for new inventories.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Got it
            </Button>
          </DialogActions>
        </Dialog>
        {podcast.hasOwnProperty("id") && inventory_categories.length && checkInventory() && inventory_categories.map((item, i) => {
          return (
            <Card className={'card'} key={`card--${item.id}`}>
              <CardContent>
                <InventoryTag>
                  <Headline>
                    <h3>Inventory name:</h3>
                  </Headline>

                  <TextField
                    label=" "
                    value={item.name}
                    placeholder={item.name}
                    onChange={(event) => {
                      handleAdcallChange(event, 'name', i);
                    }}
                    style={{ width: '80%' }}
                    disabled={item.locked}
                  />
                </InventoryTag>
                <InventoryTag>
                  <Headline>
                    <h3>URL{item.isNetwork ? " (set by network)": ""}:</h3>
                  </Headline>

                  <TextField
                    label=" "
                    multiline
                    fullWidth
                    value={item.url}
                    placeholder={item.url}
                    disabled={item.locked}
                    onChange={(event) => {
                      handleAdcallChange(event, 'url', i);
                    }}
                    style={{ width: '80%' }}
                  />
                </InventoryTag>


              </CardContent>
            </Card>
          );
        })}
      </InventoryTags>
      <Buttons>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={addInventoryCat}
          disabled={false}
          style={{ marginRight: '1rem' }}
        >
          Add inventory
        </Button>

        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={savePodcast}
          disabled={false}
        >
          Save
        </Button>
      </Buttons>
      <Feeds>
        <h2>Feeds</h2>
        {feeds.map((feed) => {
          return (
            <Card className={'card'} key={`card--${feed.url}`}>
              <CardContent>
                <Feed>
                  <Headline>
                    <h3>Feed Url:</h3>
                  </Headline>
                  <TextField
                    label=" "
                    multiline
                    fullWidth
                    value={feed.url}
                    disabled={true}
                    style={{ width: '80%' }}
                  />
                </Feed>
              </CardContent>
            </Card>
          );
        })}
      </Feeds>

      <h2>Podcast Settings</h2>
      <Card className={'card'} key={`card--${podcast.title}`}>
        <CardContent>
          <Podcast>
            <PodcastSubSettings>
              <Headline>
                <h3>Preroll Settings:</h3>
              </Headline>
              <FormControl
                component="fieldset"
                margin="normal"
                className={classes.formControl}
              >
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="ident in before preroll"
                    control={<Checkbox color="primary" />}
                    label="Ident in before preroll:"
                    labelPlacement="start"
                    checked={podcast.ident_in_before_preroll}
                    inputprops={{
                      'aria-label': 'checkbox ident in before preroll',
                    }}
                    disabled={true}
                  />
                </FormGroup>
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="ident out after preroll"
                    control={<Checkbox color="primary" />}
                    label="Ident out after preroll:"
                    labelPlacement="start"
                    checked={podcast.ident_out_after_preroll}
                    inputprops={{
                      'aria-label': 'checkbox ident out after preroll',
                    }}
                    disabled={true}
                  />
                </FormGroup>
              </FormControl>
            </PodcastSubSettings>
            <PodcastSubSettings>
              <Headline>
                <h3>Postroll Settings:</h3>
              </Headline>
              <FormControl
                component="fieldset"
                margin="normal"
                className={classes.formControl}
              >
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="ident in before postroll"
                    control={<Checkbox color="primary" />}
                    label="Ident in before postroll:"
                    labelPlacement="start"
                    checked={podcast.ident_in_before_postroll}
                    inputprops={{
                      'aria-label': 'checkbox ident in before postroll',
                    }}
                    disabled={true}
                  />
                </FormGroup>
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="ident out after postroll"
                    control={<Checkbox color="primary" />}
                    label="Ident out after postroll:"
                    labelPlacement="start"
                    checked={podcast.ident_out_after_postroll}
                    inputprops={{
                      'aria-label': 'checkbox ident out after postroll',
                    }}
                    disabled={true}
                  />
                </FormGroup>
              </FormControl>
            </PodcastSubSettings>

            <PodcastSubSettings>
              <Headline>
                <h3>Adcall Settings:</h3>
              </Headline>
              <FormControl
                component="fieldset"
                margin="normal"
                className={classes.formControl}
              >
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="adcalls enabled"
                    control={<Checkbox color="primary" />}
                    label="Adcalls enabled:"
                    labelPlacement="start"
                    checked={podcast.adcalls_enabled}
                    inputprops={{
                      'aria-label': 'checkbox adcalls enabled',
                    }}
                    disabled={true}
                  />
                </FormGroup>
              </FormControl>
            </PodcastSubSettings>
            <PodcastSubSettings>
              <Headline>
                <h3>ASAP CDN Settings:</h3>
              </Headline>
              <FormControl
                component="fieldset"
                margin="normal"
                className={classes.formControl}
              >
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="generate variations without ads"
                    control={<Checkbox color="primary" />}
                    label="⚠️ Use ASAP as CDN for ad free variations ⚠️:"
                    labelPlacement="start"
                    checked={podcast.generate_variations_without_ads}
                    inputprops={{
                      'aria-label': 'checkbox generate variations without ads',
                    }}
                    onChange={(event) => {
                      updatePodcast(event);
                    }}
                  />
                </FormGroup>
              </FormControl>
            </PodcastSubSettings>
          </Podcast>
        </CardContent>
      </Card>
    </PodcastSettings>
  );
};

const PodcastSettings = styled.div``;
const Headline = styled.div`
  h3 {
    margin-bottom: 0;
  }
  display: flex;
  justify-content: space-between;
  width: 20%;
`;

const Macros = styled.div``;
const Macro = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
`;

const Text = styled.div`
  font-size: 16px;
  margin-top: 1rem;
`;

const InventoryTags = styled.div``;
const InventoryTag = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Feeds = styled.div``;
const Feed = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
`;

const Podcast = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
`;

const PodcastSubSettings = styled.div`
  width: 100%;
  display: flex;
`;
