import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { useUser } from "../context/user";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default (props) => {
  const classes = useStyles();
  let { logOut } = props;
  let { user } = useUser();
  let { username } = user || {};

  return (
    <AppBarWrapper className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link to={"/"}>ASAP Dashboard</Link> - Hi {username}
          </Typography>
          <Button color="inherit">
            <Link
              to={{
                pathname: `/user`,
              }}
            >
              User
            </Link>
          </Button>
          <Button color="inherit" onClick={logOut}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </AppBarWrapper>
  );
};

const AppBarWrapper = styled.div`
  width: 100%;
`;
