import React from "react";
import styled from "styled-components/macro";
import FilterCodec from "./FilterCodec";

export default ({ codecs, selectedCodecs, setSelectedCodecs }) => {
  return (
    <FilterBar>
      <Filter>
        <FilterCodec
          codecs={codecs}
          selectedCodecs={selectedCodecs}
          setSelectedCodecs={setSelectedCodecs}
        />
      </Filter>
    </FilterBar>
  );
};

const FilterBar = styled.div`
  //margin-bottom: 2rem;
  flex-grow: 0;
  width: 180px;
  text-align: right;
`;

const Filter = styled.div``;
