import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useUser } from "../context/user";

function PrivateRoute({ component: Component, ...rest }) {
  const { user } = useUser() || {};
  const { username } = user || {};

  return (
    <Route
      {...rest}
      render={(props) =>
        username ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
