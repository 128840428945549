import React from "react";
import styled from "styled-components/macro";

import { withTheme } from "@material-ui/core/styles";

export default withTheme((props) => {
  let { loading, theme } = props;

  // console.log("loading", loading)

  return (
    <Loader loading={loading ? 1 : 0} theme={theme}>
      <Loading className={"loader"}>Loading...</Loading>
    </Loader>
  );
});

const Loader = styled.div`
  opacity: ${(props) => (props.loading ? "1" : "0")};

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader {
    overflow: hidden;
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0, 0, 0, 0.2);
    border-right: 1.1em solid rgba(0, 0, 0, 0.2);
    border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid ${(props) => props.theme.palette.primary.main};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Loading = styled.div``;
