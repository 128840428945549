import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from "@material-ui/icons/Edit";
import HearingIcon from "@material-ui/icons/Hearing";
import PauseCircleOutlineOutlinedIcon from "@material-ui/icons/PauseCircleOutlineOutlined";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import Loader from "./Loader";

export default (props) => {
  let {segment, removeSegment, formatTime, prelistenSegment, prelistenStop, handleEditSegment, loadingPrelistenEntity, type} = props;

  segment.type = type;
  
  
  const [editingSegment, setEditingSegment] = useState(false);
  const [segmentStartTime, setSegmentStartTime] = useState(
    formatTime(segment.startTime)
  );
  const [segmentEndTime, setSegmentEndTime] = useState(
    formatTime(segment.endTime)
  );
  const [loadingPrelisten, setLoadingPrelisten] = useState(false);

  const prevSegment = usePrevious(segment);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    let updateSegmentTimes = () => {
      if (prevSegment && prevSegment.startTime !== segment.startTime) {
        setSegmentStartTime(formatTime(segment.startTime));
      }
      if (prevSegment && prevSegment.endTime !== segment.endTime) {
        setSegmentEndTime(formatTime(segment.endTime));
      }
    };
    updateSegmentTimes();
  });

  useEffect(() => {
    let isLoading = false;
    if (!!loadingPrelistenEntity) {
      isLoading = loadingPrelistenEntity.id === segment.id;
    }
    setLoadingPrelisten(isLoading);
  }, [loadingPrelistenEntity, segment.id]);

  let handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleEditSegment(segment, segmentStartTime, segmentEndTime);
    }
  };

  let handleChangeStart = (event) => {
    setSegmentStartTime(event.target.value);
  };

  let handleChangeEnd = (event) => {
    setSegmentEndTime(event.target.value);
  };

  return (
    <Segment>
      <SegmentHeader>
        <span>
          <IconButton
            aria-label="remove segment"
            onClick={() => removeSegment(segment)}
          >
            <RemoveCircleIcon />
          </IconButton>
        </span>
        <span>{segment.labelText}</span>
        <span> | </span>
        <span>startTime: {formatTime(segment.startTime)}</span>
        <span> | </span>
        <span>endTime: {formatTime(segment.endTime)}</span>
        <span>
          <IconButton
            aria-label="prelisten segment"
            onClick={() => prelistenSegment(segment)}
          >
            <HearingIcon />
          </IconButton>
        </span>
        <span>
          <IconButton
            aria-label="stop prelisten"
            onClick={() => prelistenStop()}
          >
            <PauseCircleOutlineOutlinedIcon />
          </IconButton>
        </span>
        <span>
          <IconButton
            aria-label="edit segment"
            onClick={() => {
              setEditingSegment(!editingSegment);
            }}
          >
            <EditIcon />
          </IconButton>
        </span>
        <LoaderWrapper>
          <Loader loading={loadingPrelisten} />
        </LoaderWrapper>
      </SegmentHeader>
      <EditSegmentTime>
        <EditSegmentStartTime editingSegment={editingSegment}>
          <FormControl>
            <Input
              id={`standard-adornment-segment-time-start-${segment.id}`}
              value={segmentStartTime}
              placeholder={"hh:mm:ss.sss"}
              onChange={handleChangeStart}
              onKeyPress={handleKeyPress}
              aria-describedby="standard-segment-time-start-helper-text"
              inputprops={{
                "aria-label": "segment-time-start",
              }}
              fullWidth
              type="text"
              endAdornment={
                <InputAdornment position="end">hh:mm:ss.sss</InputAdornment>
              }
            />
            <FormHelperText id="standard-segment-time-start-helper-text">
              Start Time
            </FormHelperText>
          </FormControl>
        </EditSegmentStartTime>
        <EditSegmentEndTime editingSegment={editingSegment}>
          <FormControl>
            <Input
              id={`standard-adornment-segment-time-end-${segment.id}`}
              value={segmentEndTime}
              placeholder={"hh:mm:ss.sss"}
              onChange={handleChangeEnd}
              onKeyPress={handleKeyPress}
              aria-describedby="standard-segment-time-end-helper-text"
              inputprops={{
                "aria-label": "segment-time-end",
              }}
              fullWidth
              type="text"
              endAdornment={
                <InputAdornment position="end">hh:mm:ss.sss</InputAdornment>
              }
            />
            <FormHelperText id="standard-segment-time-end-helper-text">
              End Time
            </FormHelperText>
          </FormControl>
        </EditSegmentEndTime>
      </EditSegmentTime>
    </Segment>
  );
};

/*
 ************************************************************
 *
 *                       STYLES
 *
 ************************************************************
 */

const Segment = styled.div``;
const EditSegmentStartTime = styled.span`
  width: 220px;
  display: ${(props) => (props.editingSegment ? "inline-block" : "none")};
  margin-left: 0 20px;
`;
const EditSegmentEndTime = styled.span`
  width: 220px;
  display: ${(props) => (props.editingSegment ? "inline-block" : "none")};
  margin: 0 20px;
`;
const EditSegmentTime = styled.div`
  padding: 12px;
`;

const SegmentHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  span {
    margin: 0 2px;
  }
`;
const LoaderWrapper = styled.div`
  display: inline-block;
  padding: 12px;

  .loader {
    margin: 0 5px;
    font-size: 2.5px;
  }
`;
