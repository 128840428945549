import React from "react";
import styled from "styled-components/macro";

import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

export default ({ episode }) => {
  let { audiofile } = episode || {};

  const copyToClipboard = (event) => {
    navigator.clipboard.writeText(audiofile);
  };

  return (
    <PodcastSettings>
      <BaseUrl>
        <Headline>
          <h3>Base URL</h3>
        </Headline>
        <IconButton
          aria-label="copy url to clipboard"
          onClick={copyToClipboard}
        >
          <FileCopyOutlinedIcon />
        </IconButton>
        <TextField
          label=" "
          multiline
          fullWidth
          value={audiofile}
          inputprops={{
            readOnly: true,
          }}
          disabled
        />
      </BaseUrl>
    </PodcastSettings>
  );
};

const PodcastSettings = styled.div``;
const Headline = styled.div`
  h3 {
    margin-bottom: 0;
  }
  display: inline-block;
  width: 20%;
`;
const BaseUrl = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
`;
