import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" color="info" {...props} />;
}

export default ({ openSnackbar, setOpenSnackbar }) => {
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={openSnackbar}
      autoHideDuration={4000}
      onClose={handleCloseSnackbar}
      message="Saving completed."
    >
      <Alert onClose={handleCloseSnackbar} severity="success">
        Saving completed.
      </Alert>
    </Snackbar>
  );
};
