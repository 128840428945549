import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import { postLogout } from './api/api';
import AppBar from './components/AppBar';
import PrivateRoute from './components/PrivateRoute';
import { UserContext } from './context/user';
import Entry from './routes/entry';
import Episode from './routes/episode';
import Login from './routes/login';
import Podcast from './routes/podcast';
import PodcastAdd from './routes/podcastAdd';
import PodcastEdit from './routes/podcastEdit';
import Podcasts from './routes/podcasts';
import Usersettings from './routes/usersettings';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8cc63f',
      // main: '#5e35b1',
    },
    secondary: {
      main: '#231f20',
      // main: '#455a64'
    },
  },
});

function App() {
  let existingUser = JSON.parse(localStorage.getItem('asap'));
  let [user, setUser] = useState(existingUser);
  let { username } = user || {};

  let setExistingUser = (data) => {
    localStorage.setItem('asap', JSON.stringify(data));
    setUser(data);
  };

  let doLogOut = () => {
    setExistingUser({ username: '' });
    postLogout();
  };

  return (
    <UserContext.Provider value={{ user, setUser: setExistingUser }}>
      <CssBaseline />
      <AppContainer className="App">
        <ThemeProvider theme={theme}>
          <Router>
            <AppHeader>
              {username ? <AppBar logOut={doLogOut} /> : ``}
            </AppHeader>
            <AppBody>
              <Switch>
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/" component={Podcasts} />
                <PrivateRoute exact path="/entry" component={Entry} />
                <PrivateRoute exact path="/podcasts" component={Podcasts} />
                <PrivateRoute
                  exact
                  path="/podcasts/add"
                  component={PodcastAdd}
                />
                <PrivateRoute
                  exact
                  path="/podcasts/:podcastId(\d+)"
                  component={Podcast}
                />
                <PrivateRoute
                  exact
                  path="/podcasts/:podcastId(\d+)/edit"
                  component={PodcastEdit}
                />
                <PrivateRoute
                  path="/podcasts/:podcastId(\d+)/episodes/:episodeId(\d+)"
                  component={Episode}
                />
                <PrivateRoute exact path="/user" component={Usersettings} />
              </Switch>
            </AppBody>
          </Router>
        </ThemeProvider>
      </AppContainer>
    </UserContext.Provider>
  );
}

const AppContainer = styled.div`
  // height: 100vh;
  width: 100%;
  overflow-x: hidden;
  // overflow-y: auto;
  // overflow-y: scroll;
`;
const AppHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const AppBody = styled.div`
  padding: 1rem;
`;

export default App;
