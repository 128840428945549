import React from "react";
import styled from "styled-components/macro";

import EnclosureUrl from "./EnclosureUrl";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";

export default ({
  episode,
  podcast,
  setAdTags,
  enclosureUrl,
  setEnclosureUrl,
  podcastId,
}) => {
  return (
    <EpisodeSettings>
      <EnclosureUrl
        episode={episode}
        enclosureUrl={enclosureUrl}
        setEnclosureUrl={setEnclosureUrl}
      />
    </EpisodeSettings>
  );
};

const EpisodeSettings = styled.div``;
