import Cookies from 'js-cookie';

import { getConfig } from '../config/config';

let { restapiBaseUrl, domain } = getConfig();

/*
 ************************************************************
 *
 *                       AUTH
 *
 ************************************************************
 */

export const postLogin = async (userData) => {
  let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
  try {
    return fetch(`${restapiBaseUrl}/auth/login/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      body: JSON.stringify(userData),
    });
  } catch (e) {
    console.log(e);
  }
};

export const postLogout = async (csrftoken) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/auth/logout/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    });
  } catch (e) {
    console.log(e);
  }
};

export const postChangePassword = async (passwords) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/auth/password/change/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
      body: JSON.stringify(passwords),
    });
  } catch (e) {
    console.log('EEEEEEEE: ', e);
  }
};
//TODO Error handling

/*
 ************************************************************
 *
 *                       USER
 *
 ************************************************************
 */

export const getUser = async () => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/users/me/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    });
  } catch (e) {
    console.log(e);
  }
};

/*
 ************************************************************
 *
 *                       PODCASTS
 *
 ************************************************************
 */

export const getPodcasts = async () => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/podcasts/simple/?offset=0&limit=50`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    });
  } catch (e) {
    console.log(e);
  }
};

export const getPodcast = async (id) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/podcasts/${id}/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    });
  } catch (e) {
    console.log(e);
  }
};

export const patchPodcast = async (id, podcast) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/podcasts/${id}/`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      body: JSON.stringify(podcast),
    });
  } catch (e) {
    console.log(e);
  }
};

export const getAllPodcasts = async () => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/podcasts/all`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    });
  } catch (e) {
    console.log(e);
  }
};

/*
 ************************************************************
 *
 *                       EPISODES
 *
 ************************************************************
 */

export const getEpisodes = async (podcastId, offset = 0, limit = 50) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(
      `${restapiBaseUrl}/episodes/simple/?podcast=${podcastId}&offset=${offset}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        credentials: 'include',
      }
    );
  } catch (e) {
    console.log(e);
  }
};

export const getEpisode = async (id) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/episodes/${id}/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateArt19Metadata = async (id) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/episodes/${id}/fetch-metadata/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    }).then(response => response.json());
  } catch (e) {
    console.log(e);
  }
};

export const patchEpisode = async (id, episode) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/episodes/${id}/`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      body: JSON.stringify(episode),
    });
  } catch (e) {
    console.log(e);
  }
};

/*
 ************************************************************
 *
 *                       WAVEEDITOR
 *
 ************************************************************
 */
export const getWaveform = async (data) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch('https://b.audioadserver.com/restapi/v1/episodes/waveform/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
      body: JSON.stringify(data),
    });
  } catch (e) {
    console.log(e);
  }
};

/*
 ************************************************************
 *
 *                       ADCALLS / INVENTORY CATEGORIES
 *
 ************************************************************
 */

export const listInventory = async () => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/adcalls/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    });
  } catch (e) {
    console.log(e);
  }
};

export const getInventoryByPodcast = async (podcastId) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/adcalls/?podcast=${podcastId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    });
  } catch (e) {
    console.log(e);
  }
};

export const patchInventory = async (id, adcall) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/adcalls/${id}/`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
        referer: document.location.href,
      },
      body: JSON.stringify(adcall),
      credentials: 'include',
      // referer: document.location.href,
    });
  } catch (e) {
    console.log(e);
  }
};

export const addInventory = async (adcall) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/adcalls/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
        referer: document.location.href,
      },
      body: JSON.stringify(adcall),
      credentials: 'include',
      // referer: document.location.href,
    });
  } catch (e) {
    console.log(e);
  }
};

/*
 ************************************************************
 *
 *                       FEEDS
 *
 ************************************************************
 */

export const getFeedsByPodcast = async (podcastId) => {
  try {
    let csrftoken = Cookies.get('csrftoken', { domain: `${domain}` });
    return fetch(`${restapiBaseUrl}/feeds/?podcast=${podcastId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      credentials: 'include',
    });
  } catch (e) {
    console.log(e);
  }
};
