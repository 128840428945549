import moment from 'moment';
import React from 'react';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import SkipNextIcon from '@material-ui/icons/SkipNext';

export default ({ variations, points }) => {
  const handleJumpToMark = (mdItem, audioElementId) => {
    let player = document.getElementById(audioElementId);
    if (player) {
      player.currentTime = mdItem.time;
      player.play();
    }
  };

  return (
    <>
      {variations.map((variation, index) => {
        variation.updated = moment().format('YYYY-MM-DD hh:mm');
        return (
          <Card className={'card'} key={`card--${variation.id}`}>
            <CardContent>
              <>
                <Variation>
                  <Headline>
                    <h2>Variation {index + 1}</h2>
                  </Headline>
                  <audio
                    id={'variation-' + variation.id}
                    controls
                    preload="none"
                    style={{ width: '70vw' }}
                  >
                    <source
                      src={variation.audiofile}
                      type="audio/mpeg"
                    ></source>
                  </audio>
                </Variation>

                <Impressions>
                  <Headline>
                    <h3>Delivery Count</h3>
                  </Headline>
                  <Mark>{variation.impression_count}</Mark>
                </Impressions>

                <JumpMarks>
                  <Headline>
                    <h3>Jump Marks</h3>
                  </Headline>

                  <Mark>
                    {variation.metadata_list.map((mdItem, index) => {
                      let displayTime = new Date(mdItem.time * 1000)
                        .toISOString()
                        .substr(11, 12);
                      return (
                        <div key={index}>
                          {/* <span>Type: {mdItem.type} </span> */}
                          {/* <span>Duration: {mdItem.duration} </span> */}
                          {/* <span>{mdItem.title}</span> */}
                          <span>
                            <Button
                              size="small"
                              aria-label="Jump to mark"
                              startIcon={<SkipNextIcon />}
                              onClick={() =>
                                handleJumpToMark(
                                  mdItem,
                                  'variation-' + variation.id
                                )
                              }
                            >
                              {' '}
                              {displayTime + ' - ' + mdItem.title}
                            </Button>
                          </span>
                        </div>
                      );
                      // return <div key={mdItem.duration}>{mdItem}</div>;
                    })}
                  </Mark>
                </JumpMarks>
              </>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

const Variation = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;

const Headline = styled.div`
  h3 {
    margin-bottom: 0;
  }
  display: inline-block;
  width: 20%;
`;

const JumpMarks = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Mark = styled.div`
  flex-basis: 100%;
  margin-top: 1rem;
`;

const Impressions = styled.div`
  display: flex;
  align-items: flex-start;
`;
