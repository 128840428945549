import { filter, forEach, isEmpty, map, orderBy, union, uniq } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import AddIcon from '@material-ui/icons/Add';


import { getEpisodes, getPodcast } from "../api/api";
import FilterBar from "../components/FilterBar";
import { Divider, List, ListItem, Modal } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Search } from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../components/Loader";

export default (props) => {
    const [podcast, setPodcast] = useState([]);
    const [episodes, setEpisodes] = useState([]);
    const [episodesDisplay, setEpisodesDisplay] = useState([]);
    const [filteredEpisodes, setFilteredEpisodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCodecs, setSelectedCodecs] = React.useState([]);
    const [codecs, setCodecs] = React.useState([]);
    let { match } = props || {};
    let { params } = match || {};
    let { podcastId } = params || {};
    let lazyLoadingLimit = 25;

    /*
     ************************************************************
     *
     *                       EVENTS
     *
     ************************************************************
     */


    const useStyles = makeStyles({
        alternate: {
            webkitBoxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25)",
            boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25)",
            '&:hover': {
                backgroundColor: "rgba(0, 0, 0, .05)",
            }
        },
        default: {
            backgroundColor: "rgba(0, 0, 0, .01)",
            webkitBoxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25)",
            boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25)",
            '&:hover': {
                backgroundColor: "rgba(0, 0, 0, .05)",
            }
        },
    });

    const classes = useStyles();



    /*
     ************************************************************
     *
     *                       USE EFFECTS
     *
     ************************************************************
     */

    useEffect(() => {
        let updatePodcast = () => {
            doGetPodcast(podcastId);
        };

        let updateEpisode = () => {
            if (isEmpty(episodes)) {
                doGetEpisodes(podcastId, (episodes) => {
                    let newCodecs = [...codecs];
                    forEach(episodes, (episode) => {
                        let { codec } = episode;
                        newCodecs.push(codec);
                        // setCodecs(oldCodecs => uniq([...oldCodecs, codec]))
                        // setSelectedCodecs(oldCodecs => uniq([...oldCodecs, codec]))
                    });
                    newCodecs = uniq(newCodecs);
                    setCodecs(newCodecs);
                    setSelectedCodecs(newCodecs);
                });
            }
        };
        updatePodcast();
        updateEpisode();
    }, [codecs, episodes, podcastId]);

    useEffect(() => {
        let newFilteredEpisodes = filter(episodesDisplay, (episode) => {
            return selectedCodecs.indexOf(episode.codec) !== -1;
        });

        setFilteredEpisodes(newFilteredEpisodes);
    }, [selectedCodecs, episodes]);

    /*
     ************************************************************
     *
     *                       DATA
     *
     ************************************************************
     */

    let doGetPodcast = (podcastId) => {
        setLoading(true);
        getPodcast(podcastId)
            .then((response) => response.json())
            .then((data) => {
                setPodcast(data);
                // setLoading(false);
                // console.log("dataPodcast",data)
            });
    };

    let doGetEpisodes = (podcastId, cb) => {
        setLoading(true);
        getEpisodes(podcastId, 0, 10000)
            .then((response) => response.json())
            .then((data) => {
                let { results = [] } = data || {};
                // console.log("data", data)
                setLoading(false);
                if(results.length) {
                    setEpisodes(results);
                    setEpisodesDisplay(results);
                    cb(results);
                }
                // console.log("dataEpisodes",results)
            });
    };

    let lazyLoadMoreEpisodes = (podcastId, offset, limit) => {
        setLoading(true);
        getEpisodes(podcastId, offset, limit)
            .then((response) => response.json())
            .then((data) => {
                let { results = [] } = data || {};
                // console.log("data", data)
                setEpisodes(union(episodes, results));
                setEpisodesDisplay(union(episodes, results));
                setLoading(false);
            });
    };

    let loadNext = () => {
        console.log("LOAD NEXT STEP")
        lazyLoadMoreEpisodes(podcastId, episodes.length, lazyLoadingLimit);
    }

    /*
     ************************************************************
     *
     *                       LOGIC
     *
     ************************************************************
     */

    /*
     ************************************************************
     *
     *                       VALUES
     *
     ************************************************************
     */

    let { title = "" } = podcast;

    let orderedEpisodes = orderBy(filteredEpisodes, ["published_date"], ["desc"]);
    // let orderedEpisodes = filteredEpisodes;

    const [open, setOpen] = React.useState(false);
    const [entryTitle, setEntryTitle] = React.useState("");
    const [entryURL, setEntryURL] = React.useState("");
    const history = useHistory();

    let openModal = () => {
        setOpen(true);
    }

    let closeModal = () => {
        setOpen(false);
    }

    let verifyURL = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return pattern.test(str);
    }

    let handleNewEntry = () => {

        if(!entryTitle || entryTitle == "" || entryTitle == "Please enter a title") {
            setEntryTitle("Please enter a title");
            return;
        }

        if(!entryURL || !verifyURL(entryURL)) {
            setEntryURL("Please enter a valid URL");
            return;
        }

        let mediafile = encodeURIComponent(entryURL);
        let title = encodeURIComponent(entryTitle);
        let podcastId = encodeURIComponent(podcast.podigee_podcast_id);

        history.push(`/entry/?mediafile=${mediafile}&podcastid=${podcastId}&title=${title}`);
    }

    let searchEpisodes = (evt) => {
        let needle = evt.target.value;

        let newFilteredEpisodes = filter(episodes, (episode) => {
            return selectedCodecs.indexOf(episode.codec) !== -1;
        });

        setFilteredEpisodes(newFilteredEpisodes.filter(item => item.title.toLowerCase().includes(needle.toLowerCase())));
    }

    let handleChange = () => {}

    /*
     ************************************************************
     *
     *                       RENDER
     *
     ************************************************************
     */

    return (
        <Podcast>

            <Modal
                open={open} onClose={closeModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >

                <ModalContent>

                    <h2>Create Episode from Audiofile</h2>

                    <TextField
                        label="Title"
                        placeholder="insert title"
                        value={entryTitle}
                        onChange={(e) => { setEntryTitle(e.currentTarget.value)}}
                        style={{ width: '100%', margin: '0 0 16px 0' }}
                        // disabled={true}
                    />

                    <TextField
                        label="Audiofile"
                        placeholder="insert url"
                        value={entryURL}
                        onChange={(e) => { setEntryURL(e.currentTarget.value)}}
                        style={{ width: '100%' }}
                        // disabled={true}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        style={{ display: 'flex', marginLeft: 'auto', marginBottom: "1rem" }}
                        onClick={handleNewEntry}
                    >
                        Save
                    </Button>
                </ModalContent>


            </Modal>


            <Breadcrumbs aria-label="breadcrumb">
                <Link to={"/podcasts"}>Podcasts</Link>
                <Typography color="textPrimary">Podcast</Typography>
            </Breadcrumbs>
            <h1>
                <HeadlineInner>
                    <span>Podcast: {title}</span>
                    <IconNav>

                        <IconButton aria-label="view-settings" onClick={openModal}>
                            <AddIcon />
                        </IconButton>

                        <IconButton aria-label="view-settings">
                            <Link
                                to={{
                                    pathname: `/podcasts/${podcastId}/edit`,
                                    state: {
                                        podcast: podcast,
                                    },
                                }}
                            >
                                <SettingsIcon />
                            </Link>
                        </IconButton>
                    </IconNav>
                </HeadlineInner>
            </h1>

            <div>
                <SearchContainer>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Search />
                        </Grid>
                        <Grid item>
                            <TextField id="input-with-icon-grid" label="Search episodes" onChange={searchEpisodes} />
                        </Grid>
                    </Grid>
                    <FilterBar
                        codecs={codecs}
                        selectedCodecs={selectedCodecs}
                        setSelectedCodecs={setSelectedCodecs}
                    />
                </SearchContainer>
            </div>
            <ListContainer>
                <List>
                    {map(orderedEpisodes, (episode, index) => {
                        let { title, id, adcalls_enabled, created, published_date } = episode;

                        return (
                            <div key={id}>
                                <Link
                                    to={{
                                        pathname: `/podcasts/${podcastId}/episodes/${id}`,
                                        state: {
                                            podcast: podcast,
                                        },
                                    }}
                                >
                                    <ListItem className={index % 2 ? classes.alternate : classes.default}>
                                        <EpisodeTitle>
                                            <ListItemText primary={title} secondaryTypographyProps={{ style: { color: "red" } }} secondary={!adcalls_enabled ? "Adcalls disabled" : ""} />
                                        </EpisodeTitle>
                                        {published_date &&
                                            <div>{published_date.substr(0, 10)}</div>
                                        }
                                    </ListItem>
                                </Link>
                                {/*<Divider />*/}
                            </div>
                        );
                    })}
                </List>
            </ListContainer>
            <LoaderWrapper>
                <Loader loading={loading} />
            </LoaderWrapper>
        </Podcast>
    );
};

const Podcast = styled.div``;
const HeadlineInner = styled.div`
  display: flex;
`;
const IconNav = styled.span`
  display: inline-block;
  margin-left: auto;
`;

const SearchContainer = styled.div`
  display:flex;
`;

const ListContainer = styled.div`
  padding-top: 1em;
  //max-width: 720px;
`;
const EpisodeTitle = styled.div`
  flex: 1;
  padding:0 1em 0 0;
`

const LoaderWrapper = styled.div`
  display: inline-block;
  padding: 12px;

  .loader {
    margin: 0 5px;
    font-size: 2.5px;
  }
`;

const Views = styled.div``;
const ModalContent = styled.div`
  position: absolute;
  top: calc(50% - 125px);
  left: calc(50% - 18vw);
  width: 36vw;
  height: 250px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 1.5em;
  
  > h2 {
    padding: 0 0 12px 0;
    margin: 0;
    font-size: 1.25em;
  }

  > Button {
    margin: 24px 0 0 0;
  }
`;