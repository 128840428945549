import React, { useEffect } from "react";
import styled from "styled-components/macro";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import BaseUrl from "./BaseUrl";

export default ({ episode, enclosureUrl, setEnclosureUrl }) => {
  let { enclosure_url } = episode || {};

  // use effects for initiating enclosure_url
  useEffect(() => {
    setEnclosureUrl(enclosure_url);
  }, [enclosure_url, setEnclosureUrl]);

  const copyToClipboard = (event) => {
    navigator.clipboard.writeText(enclosureUrl);
  };

  return (
    <PodcastSettings>
      <Card className={"card"}>
        <CardContent>
          <h2>Settings</h2>

          <EnclosureUrl>
            <Headline>
              <h3>Enclosure URL</h3>
            </Headline>
            <IconButton
              aria-label="copy url to clipboard"
              onClick={copyToClipboard}
            >
              <FileCopyOutlinedIcon />
            </IconButton>
            <TextField
              multiline
              fullWidth
              value={enclosureUrl}
              inputprops={{
                readOnly: true,
              }}
              disabled
            />
          </EnclosureUrl>
          <BaseUrl episode={episode} />
        </CardContent>
      </Card>
    </PodcastSettings>
  );
};

const PodcastSettings = styled.div``;
const Headline = styled.div`
  h3 {
    margin-bottom: 0;
  }
  display: inline-block;
  width: 20%;
`;
const EnclosureUrl = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
`;
