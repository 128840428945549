import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import TextField from "@material-ui/core/TextField";

export default ({
  playHead,
  setPlayHead,
  formatTime,
  getSecondsFromFormatedTime,
}) => {
  const [playHeadTemp, setPlayHeadTemp] = useState(formatTime(playHead));

  // use effects for changing enclosure url via text fields
  useEffect(() => {
    setPlayHeadTemp(formatTime(playHead));
  }, [formatTime, playHead]);

  const handleChangePlayHead = (event) => {
    let newValue = event.target.value;
    setPlayHeadTemp(newValue);
  };

  const handleSavePlayHead = (event) => {
    let newValue = event.target.value;
    if (event.key === "Enter") {
      event.preventDefault();
      setPlayHead(getSecondsFromFormatedTime(newValue));
    }
  };

  return (
    <PlayHead>
      <TextField
        id="standard-textarea"
        label="Play Head"
        value={playHeadTemp}
        onChange={handleChangePlayHead}
        onKeyPress={handleSavePlayHead}
        type="text"
        placeholder={"hh:mm:ss.sss"}
      />
    </PlayHead>
  );
};

const PlayHead = styled.div`
  margin-left: auto;
  label {
    display: none;
  }

  div {
    margin-top: 0;
  }

  div::after,
  div::before {
    display: none;
  }
`;
