import React from "react";
import styled from "styled-components/macro";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default ({ codecs, selectedCodecs, setSelectedCodecs }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    setSelectedCodecs(event.target.value);
  };

  return (
    <FilterBar>
      <Filter>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-checkbox-label">Codecs</InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={selectedCodecs}
            onChange={handleChange}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {codecs.map((codec) => (
              <MenuItem key={codec} value={codec}>
                <Checkbox checked={selectedCodecs.indexOf(codec) > -1} />
                <ListItemText primary={codec} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Filter>
    </FilterBar>
  );
};

const FilterBar = styled.div`
  //margin-bottom: 2rem;
`;

const Filter = styled.div``;
