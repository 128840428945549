import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { getPodcasts, listInventory } from '../api/api';
import { Divider, List, ListItem, ListItemSecondaryAction } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Search } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../components/Loader";

export default (props) => {
  const [podcasts, setPodcasts] = useState([]);
  const [podcastsDisplay, setPodcastsDisplay] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);



    const useStyles = makeStyles({
        alternate: {
            webkitBoxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25)",
            boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25)",
            '&:hover': {
                backgroundColor: "rgba(0, 0, 0, .05)",
            }
        },
        default: {
            backgroundColor: "rgba(0, 0, 0, .01)",
            webkitBoxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25)",
            boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.25)",
            '&:hover': {
                backgroundColor: "rgba(0, 0, 0, .05)",
            }
        },
    });

    const classes = useStyles();

  useEffect(() => {
    doGetPodcasts();
    doListInventory();
  }, []);

  let doGetPodcasts = () => {
    getPodcasts()
      .then((response) => response.json())
      .then((data) => {
        let { results } = data;
        setLoading(false);
        setPodcasts(results || data);
        setPodcastsDisplay(results || data);
      });
  };

  let doListInventory = async () => {
    const response = await listInventory();
    const data = await response.json();
    const { results } = data;
    const inventoryDict = {};
    for (const r of results) {
      if (!inventoryDict[r.podcast]) {
        inventoryDict[r.podcast] = [];
      }
      inventoryDict[r.podcast].push(r);
    }
    setInventory(inventoryDict);
  };

  let searchPodcasts = (evt) => {
    let needle = evt.target.value;
    setPodcastsDisplay(podcasts.filter(item => item.title.toLowerCase().includes(needle.toLowerCase())));
  }

  return (
    <Podcasts>
        <div>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                    <Search />
                </Grid>
                <Grid item>
                    <TextField id="input-with-icon-grid" label="Search podcasts" onChange={searchPodcasts} />
                </Grid>
            </Grid>
        </div>
        <ListContainer>
            <List>
                  {map(podcastsDisplay, (podcast, index) => {
                    let { title, id, episodes } = podcast;

                    return (
                        <div key={id}>
                            <Link
                                  to={{
                                    pathname: `/podcasts/${id}`,
                                    state: {
                                        podcast: podcast,
                                    },
                                }}
                            >
                                <ListItem className={index % 2 ? classes.alternate : classes.default}>
                                    {/*<ListItemText primary={title} />*/}

                                    <PodcastTitle><ListItemText primary={title} /></PodcastTitle>
                                    {episodes &&
                                        <div>(600 Episoden)</div>
                                    }


                                </ListItem>
                            </Link>
                            {/*<Divider />*/}
                        </div>
                    );
                  })}
            </List>
            <LoaderWrapper>
                <Loader loading={loading} />
            </LoaderWrapper>
        </ListContainer>
    </Podcasts>
  );
};

const Podcasts = styled.div``;
const PodcastPreview = styled.div`
  width: 100%;
`;
const HeadlineInner = styled.div`
  display: flex;
`;
const ListContainer = styled.div`
  padding-top: 1em;
  //max-width: 720px;
`;
const PodcastTitle = styled.div`
  flex: 1;
`
const Title = styled.div``;
const PodcastLink = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const LoaderWrapper = styled.div`
  display: inline-block;
  padding: 12px;

  .loader {
    margin: 0 5px;
    font-size: 2.5px;
  }
`;

// const AdTags = styled.div`
//   margin-top: 0.5rem;

//   & > div {
//     display: flex;
//     align-items: center;

//     span {
//       margin-left: 0.5rem;
//       height: 1.5rem;
//       overflow: hidden;
//       text-overflow: ellipsis;
//     }
//   }
// `;
// const Adcall = styled.div``;
