exports.getConfig = () => {
  let config = {
    restapiBaseUrl: `https://b.audioadserver.com/restapi/v1`,
    domain:
      process.env.NODE_ENV === "development"
        ? `local.dashboard.b.audioadserver.com`
        : "dashboard.b.audioadserver.com",
    csrfCookieDomain: ".b.audioadserver.com",
  };

  return config;
};
