import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components/macro";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { postLogin } from "../api/api";
import { useUser } from "../context/user";
import useEventListener from "../hooks/useEventListener";

export default (props) => {
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { setUser, user } = useUser() || {};
  let { username } = user || {};


  let referer = props.location.state.referer || "/";


  let handleKeydown = (event) => {
    if (event.key === "Enter" || event.code === "Enter") {
      doPostLogin();
    }
  };

  useEventListener("keydown", handleKeydown, window);

  let doPostLogin = () => {
    let userData = {
      username: userName,
      password: password,
    };

    postLogin(userData)
      .then((response) => response.json())
      .then((data) => {
        if (!!data.non_field_errors) {
          setIsError(true);
        } else if (!!data.key) {
          setUser({ username: userName });
        }
      });
  };

  if (username) {
    return <Redirect to={referer} />;
  }

  return (
    <Login>
      <Box mb={3}>Welcome to ASAP Dashboard.</Box>
      <Box mb={3}>
        <form>
          <FormControl>
            <TextField
              id="input-with-icon-textfield"
              label="Username"
              autoComplete="username"
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              inputprops={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <Box mb={3}></Box>
            <TextField
              id="standard-password-input"
              label="Password"
              type="password"
              autoComplete="current-password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Box mb={3}></Box>
            <Button variant="contained" color="primary" onClick={doPostLogin}>
              Login
            </Button>
          </FormControl>
        </form>
      </Box>
      <Box>
        {isError && (
          <Error>The username or password provided were incorrect!</Error>
        )}
      </Box>
    </Login>
  );
};

const Login = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
`;

const Error = styled.div`
  color: red;
  font-size: 0.8em;
`;
