import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import { TextField } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MuiAlert from "@material-ui/lab/Alert";

import { getUser, postChangePassword } from "../api/api";
import Loader from "../components/Loader";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" color="info" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "50ch",
  },
}));

export default () => {
    const classes = useStyles();

    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    // const [newEmail, setNewEmail] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [errors, setErrors] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // Dialog
    const handleCloseDialog = (event) => {
        setErrors([]);
    };

    //Snackbar
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        const userSettings = async () => {
            const response = await getUser();
            const data = await response.json();

            setUsername(data.results[0].username);
            setFirstName(data.results[0].first_name);
            setLastName(data.results[0].last_name);
            setEmail(data.results[0].email);
        };
        userSettings();
    }, []);

    //Password fields
    const [values, setValues] = useState({
        amount: "",
        password: "",
        weight: "",
        weightRange: "",
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({showPassword: !values.showPassword});
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmedPasswordChange = (event) => {
        setConfirmedPassword(event.target.value);
    };

    const handleChangeValidation = () => {
        if (newPassword !== confirmedPassword) {
            setErrors(["Please make sure your password entries match."]);
        } else {
            saveUser();
        }
    };

    //Email change - not supported yet
    //   const handleEmailChange = (event) => {
    //     setNewEmail(event.target.value);
    //     saveUser();
    //   };

    //Save
    const saveUser = async () => {
        const response = await postChangePassword({
            new_password1: newPassword,
            new_password2: confirmedPassword,
        });

        //TODO write post api fuer user name etc.
        // const responseEmail = await

        //password error handling
        const data = await response.json();
        const {new_password1, new_password2} = data;

        if (new_password1) {
            setErrors(new_password1);
        } else if (new_password2) {
            setErrors(new_password2);
        } else {
            setIsSaving(true);
            setIsSaving(false);
            setOpenSnackbar(true);
        }
    };

    return (
        <User>
            <Breadcrumbs aria-label="breadcrumb">
                <Link to={"/podcasts"}>Podcasts</Link>
                <Typography color="textPrimary">User Settings</Typography>
            </Breadcrumbs>
            <h1>
                <HeadlineInner>
                    <span>User Settings:</span>
                </HeadlineInner>
            </h1>

            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={openSnackbar} autoHideDuration={4000} onClose={handleCloseSnackbar} message="New password successfully saved.">
                <Alert onClose={handleCloseSnackbar} severity="success">
                    New password successfully saved.
                </Alert>
            </Snackbar>

            <Dialog open={errors.length > 0} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Something went wrong whilst saving your new password!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {errors}
                        {/* Please make sure your password entries match. */}
                    </DialogContentText>
                    {/* <DialogContentText id="alert-dialog-description">
            Also, passwords may not be blank or contain only numbers.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            They must be at least 8 characters strong, may contain special
            characters, and shouldn't be easily guessed (eg. abc123abc).
          </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Got it!
                    </Button>
                </DialogActions>
            </Dialog>

            <Card className={"card"}>
                <CardContent>
                    <Info>
                        <SubHeadline>
                            <h3>User name</h3>
                        </SubHeadline>
                        <TextField label=" " value={username} type="readOnly" inputprops={{disableUnderline: true}}></TextField>
                    </Info>

                    <Info>
                        <SubHeadline>
                            <h3>First name</h3>
                        </SubHeadline>
                        <TextField label=" " value={firstName} type="readOnly" inputprops={{disableUnderline: true}}></TextField>
                    </Info>

                    <Info>
                        <SubHeadline>
                            <h3>Last name</h3>
                        </SubHeadline>
                        <TextField label=" " value={lastName} type="readOnly" inputprops={{disableUnderline: true}}></TextField>
                    </Info>

                    <Info>
                        <SubHeadline>
                            <h3>Email</h3>
                        </SubHeadline>
                        <InputLabel htmlFor="standard-adornment-password"></InputLabel>
                        <FormControl className={clsx(classes.textField)}>
                            <TextField label=" " value={email} type="readOnly" fullWidth inputprops={{disableUnderline: true}}></TextField>
                            {/* <Input
                type="email"
                value={email}
                label="type new email"
                onChange={handleEmailChange}
              ></Input> */}
                        </FormControl>
                    </Info>
                </CardContent>
            </Card>

            <Card>
                <CardContent>
                    <Headline>
                        <h2>Change Password</h2>
                    </Headline>

                    <Info>
                        <SubHeadline>
                            <h3>New password</h3>
                        </SubHeadline>
                        <FormControl className={clsx(classes.textField)}>
                            <Input
                                type={values.showPassword ? "text" : "password"}
                                label="type new password"
                                onChange={handleNewPasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            ></Input>
                        </FormControl>
                    </Info>
                    <Info>
                        <SubHeadline>
                            <h3>Confirm password</h3>
                        </SubHeadline>
                        <FormControl className={clsx(classes.textField)}>
                            <Input
                                type={values.showPassword ? "text" : "password"}
                                label="confirm new password"
                                onChange={handleConfirmedPasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            ></Input>
                        </FormControl>
                    </Info>
                    <Buttons>
                        <SaveButton>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveIcon />}
                                onClick={() => {
                                    handleChangeValidation();
                                }}
                            >
                                Save new password
                            </Button>
                            <LoaderWrapper>
                                <Loader loading={isSaving} />
                            </LoaderWrapper>
                        </SaveButton>
                    </Buttons>
                </CardContent>
            </Card>
        </User>
    );
};

const Headline = styled.div`
  h3 {
    margin-bottom: 0;
  }
  display: inline-block;
  width: 100%;
`;

const SubHeadline = styled.div`
  h3 {
    margin-bottom: 0;
  }
  display: inline-block;
  width: 20%;
`;

const HeadlineInner = styled.div`
  display: flex;
`;

const User = styled.div`
  flex-wrap: "wrap";
`;

const Info = styled.div`
  display: flex;
  align-content: flex-start;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;

const LoaderWrapper = styled.div`
  display: inline-block;
  padding: 12px;

  .loader {
    margin: 0 5px;
    font-size: 2.5px;
  }
`;

const SaveButton = styled.div`
  display: flex;
  align-items: center;
`;
