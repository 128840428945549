import React from "react";
import styled from "styled-components/macro";

import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

import PodcastSettings from "../components/PodcastSettings";

export default (props) => {
  return (
    <PodcastAdd>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={"/podcasts"}>Podcasts</Link>
        <Typography color="textPrimary">Add Podcast</Typography>
      </Breadcrumbs>

      <h1>Add Podcast:</h1>
      <PodcastSettings />
    </PodcastAdd>
  );
};

const PodcastAdd = styled.div``;
