import update from "immutability-helper";
import { filter, find, findIndex, forEach, isEmpty, map, uniqBy } from "lodash";
import Peaks from "peaks.js";
import React from "react";
import styled from "styled-components/macro";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import { withTheme } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Forward10Icon from "@material-ui/icons/Forward10";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Replay10Icon from "@material-ui/icons/Replay10";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";

import { getConfig } from "../config/config";
import Loader from "./Loader";
import PlayHead from "./PlayHead";
import Point from "./Point";
import Segment from "./Segment";
import { disableScroll, enableScroll } from "./disableEnableScroll";

const MINZOOMLEVEL = 64; //pixels per second
const MAXZOOMFACTOR = 16; // MAXZOOMFACTOR * MINZOOMLEVEL is the max zoom (16 * 64 = 1024)
const { restapiBaseUrl } = getConfig();

class WaveformToolkitClassComponent extends React.Component {
  constructor(props) {
    super(props);

    this.podcastId = this.props.podcastId;

    //refs
    this.audioRef = React.createRef();
    this.prelistenAdRef = React.createRef();
    this.prelistenPreRef = React.createRef();
    this.prelistenPostRef = React.createRef();

    this.peaksInstance = {};
    this.audioHowl = {};
    this.adHowl = {};

    //player
    this.setPlaying = this.setPlaying.bind(this);
    this.initPeaks = this.initPeaks.bind(this);
    this.updatePeaks = this.updatePeaks.bind(this);
    this.setZoomState = this.setZoomState.bind(this);
    this.applyZoom = this.applyZoom.bind(this);
    this.applyPlayPause = this.applyPlayPause.bind(this);
    this.skipBackward = this.skipBackward.bind(this);
    this.skipForward = this.skipForward.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);

    //points
    this.addPoint = this.addPoint.bind(this);
    this.addPreroll = this.addPreroll.bind(this);
    this.addPostroll = this.addPostroll.bind(this);
    this.removePoint = this.removePoint.bind(this);
    this.drawPoint = this.drawPoint.bind(this);
    this.prelistenWithAsapApi = this.prelistenWithAsapApi.bind(this);
    this.prelistenStop = this.prelistenStop.bind(this);
    this.handleEditPointTime = this.handleEditPointTime.bind(this);
    this.handleEditPointAdTag = this.handleEditPointAdTag.bind(this);

    //segments
    this.addSegment = this.addSegment.bind(this);
    this.drawSegment = this.drawSegment.bind(this);
    this.removeSegment = this.removeSegment.bind(this);
    this.handleEditSegment = this.handleEditSegment.bind(this);

    //helper
    this.formatTime = this.formatTime.bind(this);
    this.getSecondsFromFormatedTime = this.getSecondsFromFormatedTime.bind(
      this
    );
    this.initPointsAndSegmentsFromEpisode = this.initPointsAndSegmentsFromEpisode.bind(
      this
    );

    //playhead
    this.setPlayHead = this.setPlayHead.bind(this);

    //listener/handler
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.registerScrollZoom = this.registerScrollZoom.bind(this);
    this.unRegisterScrollZoom = this.unRegisterScrollZoom.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);

    this.state = {
      playing: false,
      zoom: 0,
      points: [],
      segments: [],
      loading: true,
      loadingPrelistenEntity: null,
      playHead: 0,
    };
  }

  /*
   ************************************************************
   *
   *                       LIFECYCLE
   *
   ************************************************************
   */

  componentDidMount() {
    this.initPeaks();
    //add listener
    document.addEventListener("keydown", this.handleKeyDown, false);
    this.registerScrollZoom();
  }

  componentDidUpdate(prevProps, prevState) {
    this.props.setPoints(this.state.points);
    this.props.setSegments(this.state.segments);

    if (prevState.playing !== this.state.playing) {
      this.applyPlayPause();
    }
    if (prevState.zoom !== this.state.zoom) {
      this.applyZoom();
    }

    if (
      prevState.points !== this.state.points &&
      prevState.points.length < this.state.points.length
    ) {
      this.drawPoint();
    }

    if (
      prevState.segments !== this.state.segments &&
      prevState.segments.length < this.state.segments.length
    ) {
      this.drawSegment();
    }

    if (prevProps.episode.audiofile !== this.props.episode.audiofile) {
      this.audioRef.current.load();
      this.prelistenAdRef.current.load();

      if (!isEmpty(this.peaksInstance)) {
        this.updatePeaks();
      } else {
        this.initPeaks();
      }
    }
  }

  componentWillUnmount() {
    //remove listener
    document.removeEventListener("keydown", this.handleKeyDown, false);
    this.unRegisterScrollZoom();
  }

  /*
   ************************************************************
   *
   *                       LISTENER
   *
   ************************************************************
   */

  handleScroll(event) {
    if (!(event.metaKey || event.ctrlKey)) {
      enableScroll(this.handleScroll);
      return;
    }

    let { deltaY, deltaX } = event || {};
    if (deltaY > 1 && Math.abs(deltaX) < 3) {
      // downscroll code
      this.zoomIn();
    } else if (deltaY < -1 && Math.abs(deltaX) < 3) {
      // upscroll code
      this.zoomOut();
    }
    if (deltaX > 1 && Math.abs(deltaY) < 3) {
      // move right
      this.peaksInstance.player.seek(
        this.peaksInstance.player.getCurrentTime() + 1
      );
    } else if (deltaX < -1 && Math.abs(deltaY) < 3) {
      // move left
      this.peaksInstance.player.seek(
        this.peaksInstance.player.getCurrentTime() - 1
      );
    }
  }

  handleKeyDown(event) {
    let { key, keyCode } = event || {};
    if (event.code === "Space") {
      event.preventDefault();
      this.setPlaying(!this.state.playing);
    }
    if (event.code === "ArrowRight") {
      event.preventDefault();
      this.skipForward(0.1);
    }
    if (event.code === "ArrowLeft") {
      event.preventDefault();
      this.skipBackward(0.1);
    }
    if (key === "+") {
      this.zoomIn();
    }
    if (key === "-") {
      this.zoomOut();
    }
    if (event.metaKey || event.ctrlKey) {
      if (event.code === "ArrowRight") {
        event.preventDefault();
        let zView = this.peaksInstance.views.getView("zoomview");
        let endTime = zView.getEndTime();
        let startTime = zView.getStartTime();
        this.skipForward(endTime - startTime);
      }
      if (event.code === "ArrowLeft") {
        event.preventDefault();
        let zView = this.peaksInstance.views.getView("zoomview");
        let endTime = zView.getEndTime();
        let startTime = zView.getStartTime();
        this.skipBackward(endTime - startTime);
      }
    }
    if (
      key === "Meta" ||
      keyCode === "91" ||
      key === "Control" ||
      keyCode === "17"
    ) {
      disableScroll(this.handleScroll);
      document.addEventListener("scroll", this.handleScroll);
    }
  }

  handleKeyUp(event) {
    let { key, keyCode } = event || {};
    if (
      key === "Meta" ||
      keyCode === "91" ||
      key === "Control" ||
      keyCode === "17"
    ) {
      document.removeEventListener("scroll", this.handleScroll);
      enableScroll(this.handleScroll);
    }
  }

  registerScrollZoom() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  }

  unRegisterScrollZoom() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  /*
   ************************************************************
   *
   *                       PEAKSJS
   *
   ************************************************************
   */

  initPeaks() {
    let { theme, episode } = this.props;
    if (!isEmpty(episode)) {
      let { palette } = theme || {};
      let { primary, secondary } = palette || {};
      let { audiowaveform } = episode || {};

      const options = {
        containers: {
          zoomview: document.getElementById("zoomview-container"),
          overview: document.getElementById("overview-container"),
        },
        mediaElement: document.querySelector("audio"),
        dataUri: {
          arraybuffer: audiowaveform,
        },
        logger: console.error.bind(console),
        keyboard: false,
        timeLabelPrecision: 2,
        zoomWaveformColor: primary.light,
        overviewWaveformColor: secondary.light,
        overviewHighlightColor: "grey",
        emitCueEvents: true,
        // default zoom
        zoomLevels: [MINZOOMLEVEL * MAXZOOMFACTOR],
      };

      this.peaksInstance = Peaks.init(
        options,
        function (err, peaks) {
          if (err) {
            console.error(
              "Failed to initialize Peaks instance: " + err.message
            );
            return;
          }

          const view = peaks.views.getView("zoomview");
          view.setAmplitudeScale(1);
          this.initPointsAndSegmentsFromEpisode();

          this.setState({
            loading: false,
          });

          peaks.on("player.timeupdate", (updatedTime) => {
            this.setState({ playHead: updatedTime });
          });
        }.bind(this)
      );
    }
  }

  updatePeaks() {
    let { episode } = this.props;
    let { audiofile, audiowaveform } = episode || {};
    let options = {
      mediaUrl: audiofile,
      dataUri: {
        arraybuffer: audiowaveform,
      },
    };

    this.peaksInstance.setSource(options, () => {
      // console.log("source updated!")
    });
  }

  /*
   ************************************************************
   *
   *                       NAVIGATION
   *
   ************************************************************
   */

  setPlaying(playing) {
    this.setState({
      playing: playing,
    });
  }

  setZoomState(event, newValue) {
    this.setState({
      zoom: newValue,
    });
  }

  applyZoom() {
    const view = this.peaksInstance.views.getView("zoomview");
    let minZoom = MINZOOMLEVEL;
    let maxZoom = MAXZOOMFACTOR * minZoom;
    view.setZoom({
      scale: maxZoom - (this.state.zoom / 100) * (maxZoom - minZoom),
    }); // samples per pixel
  }

  zoomIn() {
    let currentZoom = this.state.zoom;
    let zoomStep = 10;
    let newZoom = currentZoom + zoomStep;
    if (newZoom <= 100) {
      this.setState({
        zoom: newZoom,
      });
    } else {
      this.setState({
        zoom: 100,
      });
    }
  }

  zoomOut() {
    let currentZoom = this.state.zoom;
    let zoomStep = 10;
    let newZoom = currentZoom - zoomStep;
    if (newZoom >= 0) {
      this.setState({
        zoom: newZoom,
      });
    } else {
      this.setState({
        zoom: 0,
      });
    }
  }

  applyPlayPause() {
    if (this.state.playing) {
      this.peaksInstance.player.play();
    } else {
      this.peaksInstance.player.pause();
    }
  }

  skipBackward(seconds) {
    let currentTime = this.peaksInstance.player.getCurrentTime();
    this.peaksInstance.player.seek(currentTime - seconds);
  }

  skipForward(seconds) {
    let currentTime = this.peaksInstance.player.getCurrentTime();
    this.peaksInstance.player.seek(currentTime + seconds);
  }

  /*
   ************************************************************
   *
   *                       POINTS
   *
   ************************************************************
   */

  addPoint() {
    let pointTime = this.peaksInstance.player.getCurrentTime();
    let newPoint = {
      time: pointTime,
      labelText: `Ad break`,
      color: "#006eb0",
      editable: true,
      id: `peaks.point.${Date.now()}`,
    };

    let newPoints = this.state.points.concat(newPoint);

    this.setState({
      points: uniqBy(newPoints, "time"),
    });
    this.props.setChanged(true);
  }

  addPreroll() {
    let pointTime = 0;
    let newPoint = {
      time: pointTime,
      labelText: `Preroll`,
      color: "#006eb0",
      editable: true,
      id: `peaks.point.${Date.now()}`,
    };

    let newPoints = this.state.points.concat(newPoint);

    this.setState({
      points: uniqBy(newPoints, "time"),
    });
    this.props.setChanged(true);
  }

  addPostroll() {
    let pointTime = this.peaksInstance.player.getDuration();
    let newPoint = {
      time: pointTime,
      labelText: `Postroll`,
      color: "#006eb0",
      editable: true,
      id: `peaks.point.${Date.now()}`,
    };

    let newPoints = this.state.points.concat(newPoint);

    this.setState({
      points: uniqBy(newPoints, "time"),
    });
    this.props.setChanged(true);
  }

  drawPoint() {
    let pointsAlreadyDrawn = this.peaksInstance.points.getPoints();
    forEach(this.state.points, (point, index) => {
      //don't add if already drawn
      if (!find(pointsAlreadyDrawn, { time: point.time })) {
        // console.log("point", point)
        this.peaksInstance.points.add(point);
        this.peaksInstance.on("points.dragmove", (pointMoved) => {
          if (point.id === pointMoved.id) {
            this.setState({
              points: update(this.state.points, {
                [index]: {
                  time: { $set: pointMoved.time },
                },
              }),
            });
          }
        });
      }
    });
  }

  removePoint(point) {
    let reducedPoints = filter(this.state.points, (_point) => {
      return _point.time !== point.time;
    });

    this.setState({
      points: reducedPoints,
    });
    this.props.setChanged(true);

    this.peaksInstance.points.removeByTime(point.time);
  }

  async prelistenWithAsapApi(pointOrSegment) {
    let { peaksInstance, prelistenAdRef } = this;
    let { episode } = this.props;
    let { player } = peaksInstance || {};
    let { time, startTime, endTime } = pointOrSegment || {};
    let { id } = episode;

    let prelistenStart = time ? time : startTime ? startTime : 0;
    let prelistenEnd = endTime ? `,${endTime}` : "";

    let prelistenSrc = `${restapiBaseUrl}/episodes/${id}/prelisten?t=${prelistenStart}${prelistenEnd}`;

    try {
      if (!!pointOrSegment.adCall) {
        let base64AdCall = window.btoa(pointOrSegment.adCall);
        prelistenSrc = prelistenSrc.concat(`&adcall=${base64AdCall}`);
      }

      player.seek(prelistenStart);
      prelistenAdRef.current.src = prelistenSrc;
      prelistenAdRef.current.load();

      this.setState({
        loadingPrelistenEntity: pointOrSegment,
      });

      prelistenAdRef.current.oncanplaythrough = () => {
        this.setState({
          loadingPrelistenEntity: null,
        });
        prelistenAdRef.current.play();
      };
    } catch (e) {
      //console.log(e)
    }
  }

  async prelistenStop() {
    this.prelistenAdRef.current.pause();
  }

  handleEditPointTime(point, newTimeString) {
    let peaksPoint = this.peaksInstance.points.getPoint(point.id);
    let peaksDuration = this.peaksInstance.player.getDuration();
    let newTime = parseFloat(this.getSecondsFromFormatedTime(newTimeString));
    let statePointIndex = findIndex(this.state.points, { id: point.id });

    if (!newTime || newTime < 0) {
      newTime = 0;
    }

    if (newTime + 0.2 >= peaksDuration) {
      newTime = peaksDuration - 0.2;
    }

    this.peaksInstance.player.seek(newTime);
    peaksPoint.update({ time: newTime });
    this.setState({
      points: update(this.state.points, {
        [statePointIndex]: { time: { $set: newTime } },
      }),
    });
    this.props.setChanged(true);
  }

  handleEditPointAdTag(point, newAdCall) {
    let peaksPoint = this.peaksInstance.points.getPoint(point.id);
    let statePointIndex = findIndex(this.state.points, { id: point.id });

    peaksPoint.update({ adcall: newAdCall });

    this.setState({
      points: update(this.state.points, {
        [statePointIndex]: { adcall: { $set: newAdCall } },
      }),
    });
    this.props.setChanged(true);
  }

  /*
   ************************************************************
   *
   *                       SEGMENTS
   *
   ************************************************************
   */

  addSegment() {
    let newSegment = {
      startTime: this.peaksInstance.player.getCurrentTime(),
      endTime: this.peaksInstance.player.getCurrentTime() + 3,
      labelText: `Removal`,
      color: "#006af4",
      editable: true,
      id: `peaks.segment.${Date.now()}`,
    };

    let newSegments = this.state.segments.concat(newSegment);

    this.setState({
      segments: uniqBy(newSegments, (segment) =>
        [segment.startTime, segment.endTime].join()
      ),
    });
    this.props.setChanged(true);
  }

  drawSegment() {
    let segmentsAlreadyDrawn = this.peaksInstance.segments.getSegments();
    forEach(this.state.segments, (segment, index) => {
      //don't add if already drawn
      if (
        !find(segmentsAlreadyDrawn, {
          startTime: segment.startTime,
          endTime: segment.endTime,
        })
      ) {
        this.peaksInstance.segments.add(segment);
        this.peaksInstance.on("segments.dragged", (segmentMoved) => {
          if (segment.id === segmentMoved.id) {
            this.setState({
              segments: update(this.state.segments, {
                [index]: {
                  startTime: { $set: segmentMoved.startTime },
                  endTime: { $set: segmentMoved.endTime },
                },
              }),
            });
          }
        });
      }
    });
  }

  removeSegment(segment) {
    let reducedSegments = filter(this.state.segments, (_segment) => {
      return (
        _segment.startTime !== segment.startTime &&
        _segment.endTime !== segment.endTime
      );
    });

    this.setState({
      segments: reducedSegments,
    });
    this.props.setChanged(true);

    this.peaksInstance.segments.removeByTime(
      segment.startTime,
      segment.endTime
    );
  }

  handleEditSegment(segment, newTimeStartString, newTimeEndString) {
    let peaksSegment = this.peaksInstance.segments.getSegment(segment.id);
    let peaksDuration = this.peaksInstance.player.getDuration();
    let stateSegmentIndex = findIndex(this.state.segments, { id: segment.id });

    let newTimeStart = parseFloat(
      this.getSecondsFromFormatedTime(newTimeStartString)
    );

    if (!newTimeStart || newTimeStart < 0) {
      newTimeStart = 0;
    }

    if (newTimeStart + 0.2 >= peaksDuration) {
      newTimeStart = peaksDuration - 0.2;
    }

    let newTimeEnd = parseFloat(
      this.getSecondsFromFormatedTime(newTimeEndString)
    );

    if (!newTimeEnd || newTimeEnd < 0) {
      newTimeEnd = 0;
    }

    if (newTimeEnd + 0.2 >= peaksDuration) {
      newTimeEnd = peaksDuration - 0.2;
    }

    if (newTimeEnd < newTimeStart) {
      let temp = newTimeEnd;
      newTimeEnd = newTimeStart;
      newTimeStart = temp;
    }

    this.peaksInstance.player.seek(newTimeStart);
    peaksSegment.update({ startTime: newTimeStart, endTime: newTimeEnd });

    this.setState({
      segments: update(this.state.segments, {
        [stateSegmentIndex]: {
          startTime: { $set: newTimeStart },
          endTime: { $set: newTimeEnd },
        },
      }),
    });
    this.props.setChanged(true);
  }

  /*
   ************************************************************
   *
   *                       HELPERS
   *
   ************************************************************
   */

  formatTime(seconds) {
    let formattedTime = new Date(seconds * 1000).toISOString().substr(11, 12);
    return formattedTime;
  }

  getSecondsFromFormatedTime(formattedTimeString) {
    let hours = parseFloat(formattedTimeString.split(":")[0]);
    let minutes = parseFloat(formattedTimeString.split(":")[1]);
    let seconds = parseFloat(formattedTimeString.split(":")[2]);

    let fullSeconds = (hours * 60 + minutes) * 60 + seconds;
    return fullSeconds;
  }

  initPointsAndSegmentsFromEpisode() {
    let { episode } = this.props;
    let { markers_json = "{}" } = episode || {};

    if (isEmpty(markers_json)) {
      markers_json = "{}";
    }

    let markers_json_obj = JSON.parse(markers_json);
    let points = filter(markers_json_obj, (marker) => {
      return "time" in marker;
    });
    let segments = filter(markers_json_obj, (marker) => {
      return "startTime" in marker;
    });

    this.setState({
      points: points,
      segments: segments,
    });
  }

  /*
   ************************************************************
   *
   *                       PlayHead
   *
   ************************************************************
   */

  setPlayHead(time) {
    this.peaksInstance.player.seek(time);
    return;
  }

  /*
   ************************************************************
   *
   *                       RENDER
   *
   ************************************************************
   */

  render() {
    let { episode = {}, hasAdsEnabled, setHasAdsEnabled } = this.props;
    let { audiofile } = episode;

    return (
      <WaveformToolkit>
        <div id="peaks-container">
          <div id="zoomview-container"></div>
          <div id="overview-container"></div>
          <LoaderWrapper
            className={`loader--${this.state.loading ? "visible" : "hidden"}`}
          >
            <Loader loading={this.state.loading} />
          </LoaderWrapper>
        </div>
        <Audio>
          <audio id="audio" ref={this.audioRef} preload="auto">
            <source src={audiofile} type="audio/mpeg" />
          </audio>
        </Audio>
        <PrelistenAudio>
          <audio
            id="prelisten-ad"
            ref={this.prelistenAdRef}
            preload="auto"
            src="/media/prelistenAd.mp3"
            type="audio/mp3"
          />
        </PrelistenAudio>
        <Navigation>
          <PlayPause>
            {this.state.playing ? (
              <IconButton
                aria-label="pause"
                onClick={() => {
                  this.setPlaying(false);
                }}
              >
                <PauseCircleOutlineIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="play"
                onClick={() => {
                  this.setPlaying(true);
                }}
              >
                <PlayCircleOutlineIcon />
              </IconButton>
            )}
          </PlayPause>
          <SkipPrevNext>
            <IconButton
              aria-label="skip prev"
              onClick={() => {
                this.skipBackward(0.1);
              }}
            >
              <Replay10Icon />
            </IconButton>
            <IconButton
              aria-label="skip next"
              onClick={() => {
                this.skipForward(0.1);
              }}
            >
              <Forward10Icon />
            </IconButton>
          </SkipPrevNext>
          <PlayHead
            playHead={this.state.playHead}
            setPlayHead={this.setPlayHead}
            formatTime={this.formatTime}
            getSecondsFromFormatedTime={this.getSecondsFromFormatedTime}
          />
          <ZoomInOut>
            <Grid container spacing={2}>
              <Grid item>
                <ZoomOutIcon onClick={this.zoomOut} />
              </Grid>
              <Grid item xs>
                <Slider
                  value={this.state.zoom}
                  onChange={this.setZoomState}
                  aria-labelledby="continuous-slider"
                />
              </Grid>
              <Grid item>
                <ZoomInIcon onClick={this.zoomIn} />
              </Grid>
            </Grid>
          </ZoomInOut>
        </Navigation>
        <PointList>
          <Card className={"card"}>
            <CardContent>
              <h2>Adbreaks</h2>
              <HasPrePostRolls>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasAdsEnabled}
                          onChange={() => setHasAdsEnabled(!hasAdsEnabled)}
                          inputprops={{ "aria-label": "primary checkbox" }}
                        />
                      }
                      label="Ads enabled"
                    />
                  </FormGroup>
                </FormControl>
              </HasPrePostRolls>
              {/* <AddPoint>
                <IconButton aria-label="add Preroll" onClick={this.addPreroll}>
                  <AddCircleOutlineIcon />
                </IconButton>
                Create new preroll at time 0!
              </AddPoint> */}
              <AddPoint>
                <IconButton aria-label="add Point" onClick={this.addPoint}>
                  <AddCircleOutlineIcon />
                </IconButton>
                Create new ad break at cursor position!
              </AddPoint>
              {/* <AddPoint>
                <IconButton
                  aria-label="add Postroll"
                  onClick={this.addPostroll}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
                Create new postroll at the end!
              </AddPoint> */}
              {map(this.state.points, (point, index) => {
                return (
                    <Point
                        key={`point--${index}`}
                        point={point}
                        removePoint={this.removePoint}
                        prelistenPoint={this.prelistenWithAsapApi}
                        prelistenStop={this.prelistenStop}
                        formatTime={this.formatTime}
                        handleEditPointTime={this.handleEditPointTime}
                        handleEditPointAdTag={this.handleEditPointAdTag}
                        loadingPrelistenEntity={this.state.loadingPrelistenEntity}
                        podcastId={this.podcastId}
                        type="point"
                    />
                );
              })}
            </CardContent>
          </Card>
        </PointList>
        <SegmentList>
          <Card className={"card"}>
            <CardContent>
              <h2>Removals</h2>
              <AddSegment>
                <IconButton aria-label="add Segment" onClick={this.addSegment}>
                  <AddCircleOutlineIcon />
                </IconButton>
                Create Removal at cursor position! Press "Enter" to save
                timecode!
              </AddSegment>
              {map(this.state.segments, (segment, index) => {
                return (
                    <Segment
                        key={`segment--${index}`}
                        segment={segment}
                        removeSegment={this.removeSegment}
                        prelistenSegment={this.prelistenWithAsapApi}
                        prelistenStop={this.prelistenStop}
                        formatTime={this.formatTime}
                        handleEditSegment={this.handleEditSegment}
                        loadingPrelistenEntity={this.state.loadingPrelistenEntity}
                        type="segment"
                    />
                );
              })}
            </CardContent>
          </Card>
        </SegmentList>
      </WaveformToolkit>
    );
  }
} // end of class definition

/*
 ************************************************************
 *
 *                       STYLES
 *
 ************************************************************
 */

const WaveformToolkit = styled.div``;
const Navigation = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  // background: #231f20;
  background: rgba(0, 0, 0, 0.54);
  * {
    color: white !important;
  }

  svg {
    cursor: pointer;
  }
`;
const Audio = styled.div``;
const PrelistenAudio = styled.div``;
const PlayPause = styled.div``;
const ZoomInOut = styled.div`
  margin-left: auto;
  width: 200px;
  padding-top: 10px;
`;
const SkipPrevNext = styled.div``;
const AddPoint = styled.div``;
const PointList = styled.div``;
const AddSegment = styled.div``;
const SegmentList = styled.div``;
const HasPrePostRolls = styled.div`
  padding: 12px;
`;

const LoaderWrapper = styled.div`
  display: none;
  &.loader--visible {
    display: block;
  }
`;

/*
 ************************************************************
 *
 *                       EXPORT
 *
 ************************************************************
 */

export default withTheme(WaveformToolkitClassComponent);
