import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import HearingIcon from "@material-ui/icons/Hearing";
import PauseCircleOutlineOutlinedIcon from "@material-ui/icons/PauseCircleOutlineOutlined";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import { getInventoryByPodcast } from "../api/api";
import Loader from "./Loader";

export default (props) => {
  let {point, removePoint, formatTime, prelistenPoint, prelistenStop, handleEditPointTime, handleEditPointAdTag, loadingPrelistenEntity, podcastId, type} = props;
  point.type = type;

  const [editingPoint, setEditingPoint] = useState(false);
  const [pointTime, setPointTime] = useState(formatTime(point.time));
  const [loadingPrelisten, setLoadingPrelisten] = useState(false);
  const [inventoryCategories, setInventoryCategories] = useState([]);

  const prevPoint = usePrevious(point);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    const updatePointTime = () => {
      if (prevPoint && prevPoint.time !== point.time) {
        setPointTime(formatTime(point.time));
      }
    };
    updatePointTime();
  });

  useEffect(() => {
    let isLoading = false;
    if (!!loadingPrelistenEntity) {
      isLoading = loadingPrelistenEntity.id === point.id;
    }
    setLoadingPrelisten(isLoading);
  }, [loadingPrelistenEntity, point.id]);

  useEffect(() => {
    const doGetInventory = async () => {
      const response = await getInventoryByPodcast(podcastId);
      const data = await response.json();
      const { results } = data;
      setInventoryCategories(results);
    };

    doGetInventory();
  }, [podcastId]);

  const handleEnterNewTime = (event) => {
    let newValue = event.target.value;
    if (event.key === "Enter") {
      handleEditPointTime(point, newValue);
    }
  };

  const handleChangePointTime = (event) => {
    setPointTime(event.target.value);
  };

  const handleInventoryInputChange = (event) => {
    let newValue = event.target.value;
    handleEditPointAdTag(point, newValue);
  };

  return (
    <Point>
      <Card className={"card"}>
        <CardContent>
          <PointInner>
            <PointHeader>
              <span>
                <IconButton
                  aria-label="remove point"
                  onClick={() => removePoint(point)}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </span>
              <span>{point.labelText}</span>
              <span> | </span>
              <PointTime>({formatTime(point.time)})</PointTime>
              <span>
                <IconButton
                  aria-label="prelisten point"
                  // onClick={() => prelistenPoint(point)}
                  // onClick={() => prelistenPointWithFragments(point)}
                  // onClick={() => prelistenPointWithHowler(point)}
                  // onClick={() => prelistenPointWithInterval(point)}
                  onClick={() => prelistenPoint(point)}
                >
                  <HearingIcon />
                </IconButton>
              </span>
              <span>
                <IconButton
                  aria-label="stop prelisten"
                  onClick={() => prelistenStop()}
                >
                  <PauseCircleOutlineOutlinedIcon />
                </IconButton>
              </span>
              <span>
                <IconButton
                  aria-label="edit point"
                  onClick={() => {
                    setEditingPoint(!editingPoint);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </span>
              <span>
                <h4>Inventory Category:</h4>
              </span>
              <span>
                <TextField
                  label=" "
                  value={point.adcall}
                  select
                  onChange={(event) => {
                    handleInventoryInputChange(event, "inventoryCategories");
                  }}
                >
                  {inventoryCategories.map((inv) => {
                    let { id, name } = inv;
                    return (
                      <MenuItem key={`menuitem--${id}`} value={id}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </span>
              <LoaderWrapper>
                <Loader loading={loadingPrelisten} />
              </LoaderWrapper>
            </PointHeader>
            <PointBody>
              <EditPointTimeWrapper>
                <EditPointTime editingPoint={editingPoint}>
                  <FormControl>
                    Press the Enter key to save!
                    <Input
                      id={`standard-adornment-point-time--${point.id}`}
                      value={pointTime}
                      placeholder={"hh:mm:ss.sss"}
                      onChange={handleChangePointTime}
                      onKeyPress={handleEnterNewTime}
                      aria-describedby={`standard-point-time-helper-text--${point.id}`}
                      inputprops={{
                        "aria-label": "point-time",
                      }}
                      fullWidth
                      type="text"
                      endAdornment={
                        <InputAdornment position="end">
                          hh:mm:ss.sss
                        </InputAdornment>
                      }
                    />
                    <FormHelperText
                      id={`standard-point-time-helper-text--${point.id}`}
                    >
                      Time
                    </FormHelperText>
                  </FormControl>
                </EditPointTime>
              </EditPointTimeWrapper>
            </PointBody>
          </PointInner>
        </CardContent>
      </Card>
    </Point>
  );
};

/*
 ************************************************************
 *
 *                       STYLES
 *
 ************************************************************
 */

const Point = styled.div`
  span {
    margin: 0 2px;
  }
`;
const PointInner = styled.div``;

const PointHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const PointBody = styled.div``;

const PointTime = styled.span``;
const EditPointTimeWrapper = styled.div`
  flex: 0 0 100%;
  padding: 12px;
`;

const EditPointTime = styled.span`
  display: ${(props) => (props.editingPoint ? "block" : "none")};
  max-width: 300px;

  & > div {
    width: 100%;
  }
`;

const LoaderWrapper = styled.div`
  display: inline-block;
  padding: 12px;

  .loader {
    margin: 0 5px;
    font-size: 2.5px;
  }
`;
